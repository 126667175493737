import './Welcome.scss'
import LandingHeader from '../../Components/WelcomeComponents/LandingHeader/LandingHeader'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import React, {useContext, useEffect, useRef} from 'react'
import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

import futureSectionFirst from '../../Assets/images/welcome-2-section-1.svg'
import futureSectionSecond from '../../Assets/images/welcome-2-section-2.svg'
import bgSectionThird from '../../Assets/images/welcome-3-section.svg'
import brainstorming from '../../Assets/icons/brainstorming.svg'
import brainstormingOrange from '../../Assets/icons/brainstorming_orange.svg'
import nanotechnology from '../../Assets/icons/nanotechnology.svg'
import nanotechnologyOrange from '../../Assets/icons/nanotechnology_orange.svg'
import communityOrange from '../../Assets/icons/community_orange.svg'
import screen1 from '../../Assets/images/screen1.svg'
import screen2 from '../../Assets/images/screen2.svg'
import screen3 from '../../Assets/images/screen3.svg'
import macbook from '../../Assets/images/macbook.svg'
import iphone from '../../Assets/images/iphone.svg'
import {Link} from "react-router-dom";
import androidBtn from "../../Assets/images/android_btn.svg";
import appleBtn from "../../Assets/images/apple_btn.svg";
import fullLogo from "../../Assets/images/full_logo.svg";
import welcomeVideo from "../../Assets/video/welcome.mp4";

gsap.registerPlugin(ScrollTrigger);

const Welcome = () => {
  const containerRef = useRef(null);
  const containerTitleRef = useRef(null);
  const elementRef1 = useRef(null);
  const elementRef2 = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const containerTitle = containerTitleRef.current;
    const element1 = elementRef1.current;
    const element2 = elementRef2.current;

    gsap.set(element1, {x: 0, opacity: 1});
    gsap.set(element2, {x: 200, opacity: 0});

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: container,
        pin: true,
        pinSpacing: true,
        pinnedContainer: container,
        start: 'center center',
        end: '+=200vh',
        scrub: .9,
        toggleActions: "play pause reverse none",
      }
    });

    tl.to(element1, {
      x: -200,
      opacity: 0,
      duration: 1,
    });

    // Анимация второго элемента: появление справа
    tl.to(
      element2,
      {
        x: 0,
        opacity: 1,
        duration: 1,
      }, "-=0.9" // Начинается чуть раньше, чем завершится анимация element1
    );

    // Анимация второго элемента: появление справа
    tl.to(
      containerTitle,
      {
        scale: 1.2,
      }, "-=0.9"
    );

    return () => {
      ScrollTrigger.getAll().forEach((st) => st.kill());
    };
  }, []);

  return (
    <div className="welcome-wrapper animate-fade-in">
      <LandingHeader/>

      <section className="section-welcome d-flex flex-column align-items-center text-center">
        <span className="welcome-title">Introducing the <br/> <span className="orange">Semantic Imprinting Engine</span></span>
        <span className="welcome-subtitle">The Future of Online Interaction</span>
        <video src={welcomeVideo} loop autoPlay muted></video>

        <div className="align-items-center d-flex d-md-none buttons">
          <Link to="" className="link-img">
            <img src={androidBtn} alt=""/>
          </Link>
          <Link to="" className="link-img">
            <img src={appleBtn} alt=""/>
          </Link>
          <Link to="/getaccess" className="btn-header">
            Add to Chrome
          </Link>
        </div>
      </section>

      <section className="section-2 d-none d-lg-flex" ref={containerRef}>
        <span className="section-title" ref={containerTitleRef}><span className="orange">The Future</span> of Online Interaction</span>
        <div className="before element" ref={elementRef1}>
          <div className="section-2-text">
            <span>At Comment8, we’re redefining how we engage with the internet.</span>
            <span>With the <span className="bold">Semantic Imprinting Engine</span>, the web becomes more personal, collaborative, and meaningful.</span>
          </div>
          <img className="section-2-img" src={futureSectionFirst} alt=""/>
        </div>
        <div className="after element" ref={elementRef2}>
          <div className="section-2-text">
            <span>This <span className="bold">breakthrough technology</span> transforms the web into a space where you contribute effortlessly, turning it into a seamless, borderless conversation.</span>
          </div>
          <img className="section-2-img" src={futureSectionSecond} alt=""/>
        </div>
        <div className="d-flex align-items-center mt-auto">

        </div>
      </section>

      <section className="section-2 d-flex d-lg-none">
        <span className="section-title"><span className="orange">The Future</span> of Online Interaction</span>
        <div className="before element">
          <div className="section-2-text">
            <span>At Comment8, we’re redefining how we engage with the internet.</span>
            <span>With the <span className="bold">Semantic Imprinting Engine</span>, the web becomes more personal, collaborative, and meaningful.</span>
          </div>
          <img className="section-2-img" src={futureSectionFirst} alt=""/>
        </div>
        <div className="after element">
          <div className="section-2-text">
            <span>This <span className="bold">breakthrough technology</span> transforms the web into a space where you contribute effortlessly, turning it into a seamless, borderless conversation.</span>
          </div>
          <img className="section-2-img" src={futureSectionSecond} alt=""/>
        </div>
        <div className="d-flex align-items-center mt-auto">

        </div>
      </section>

      <section className="section-3">
        <div className="d-flex flex-column align-items-center">
          <span className="section-title-white"><span className="orange">Your Mark</span>, Everywhere</span>
          <span className="section-info">The Semantic Imprinting Engine (SIE) embeds your voice directly into the web. Leave comments, ask questions, or share insights—anywhere, anytime, without plugins or permission.</span>
          <span className="section-subtitle">It’s like turning the entire internet into your personal canvas.</span>
        </div>
        <img src={bgSectionThird} alt="" className="bgc"/>
      </section>

      <section className="section-4">
        <div className="d-flex flex-column align-items-center">
          <span className="section-title">Built for Effortless Connection</span>
          <span className="section-info">SIE isn’t just another commenting tool. It’s powered by:</span>
        </div>
        <div className="features">
          <div className="feature">
            <img src={brainstorming} alt=""/>
            <span className="feature-name">Advanced Machine Learning <br/> to understand context.</span>
          </div>
          <div className="feature">
            <img src={nanotechnology} alt=""/>
            <span className="feature-name">Proprietary Anchoring Technology to place your thoughts exactly where they belong.</span>
          </div>
        </div>
        <div className="d-flex flex-column align-items-center">
          <span className="section-title">Seamless Simplicity</span>
          <span className="section-info">Conversations happen where they should—right alongside the content you care about.</span>
        </div>
        <div className="screens">
          <div className="screen">
            <img src={screen1} alt=""/>
            <div className="screen-info">
              <span>No extra apps or complicated threads.</span>
            </div>
          </div>
          <div className="screen">
            <img src={screen2} alt=""/>
          </div>
          <div className="screen">
            <img src={screen3} alt=""/>
            <div className="screen-info">
              <span>Interactions feel fluid and personal, fostering real connections.</span>
            </div>
          </div>
        </div>
      </section>

      <section className="section-5">
        <span className="section-title-white">A Network <span className="orange">Without Limits</span></span>
        <span className="section-subtitle">
          SIE follows you everywhere—across devices, browsers, and domains. <br/>
          It’s not just inclusive; <span className="semibold">it’s infinite.</span>
        </span>
        <img src={iphone} className="iphone" alt=""/>
        <img src={macbook} className="macbook" alt=""/>
      </section>

      <section className="section-6">
        <div className="d-flex flex-column align-items-center">
          <span className="section-title">For the <span className="orange">Bold</span> Thinkers</span>
          <span className="section-info" style={{marginTop: "16px"}}>The Semantic Imprinting Engine empowers:</span>
        </div>
        <div className="features">
          <div className="feature">
            <img src={brainstormingOrange} alt=""/>
            <span className="feature-name">Creators to connect deeply <br/> with their audiences.</span>
          </div>
          <div className="feature">
            <img src={communityOrange} alt=""/>
            <span className="feature-name">Thinkers to engage in <br/> meaningful dialogue.</span>
          </div>
          <div className="feature">
            <img src={nanotechnologyOrange} alt=""/>
            <span className="feature-name">Everyone ready for an internet <br/> built for understanding.</span>
          </div>
        </div>
        <div className="d-flex flex-column align-items-center">
          <span className="section-title">Join the Revolution</span>
          <span className="section-info" style={{marginTop: "45px"}}>The Semantic Imprinting Engine isn’t just a tool—it’s a promise to make the web simpler, more impactful, and deeply inspiring.</span>
          <span className="section-info text-center" style={{marginTop: "35px"}}>
            <span className="semibold">Ready to shape the internet’s future?</span><br/>
            Explore Comment8 today and experience the transformation.
          </span>
        </div>
        <div className="buttons">
          <Link to="" className="link-img">
            <img src={androidBtn} alt=""/>
          </Link>
          <Link to="" className="link-img">
            <img src={appleBtn} alt=""/>
          </Link>
          <Link to="/getaccess" className="btn-header">
            Add to Chrome
          </Link>
        </div>
      </section>
      <section className="section-7">
        <span className="section-title">The internet just got personal. Welcome to the future.</span>
      </section>
      <footer>
        <img src={fullLogo} alt=""/>
        <span>© 2024 Comment8. All rights reserved.</span>
        <span>Empowering meaningful connections across the web.</span>
        <span>Comment8 is a subsidiary of Sokhey LLC</span>
      </footer>
    </div>
  );
}
export default Welcome
