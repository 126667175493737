import './LandingHeader.scss'
import { Link } from 'react-router-dom'
import React from 'react'

import headerLogoWhite from '../../../Assets/icons/logo.svg'
import androidBtn from '../../../Assets/images/android_btn.svg'
import appleBtn from '../../../Assets/images/apple_btn.svg'

const LandingHeader = () => {
  return(
    <>
      <header className={`welcome-header`}>
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex align-items-center">
            <img width={35} src={headerLogoWhite} alt=""/>
            <span className="logo-title">Comment8</span>
          </div>
          <div className="align-items-center d-none d-md-flex">
            <Link to="" className="link-img">
              <img src={androidBtn} alt=""/>
            </Link>
            <Link to="" className="ms-3 link-img">
              <img src={appleBtn} alt=""/>
            </Link>
            <Link to="/getaccess" className="btn-header ms-3">
              Add to Chrome
            </Link>
          </div>
        </div>
      </header>
    </>
  )
}
export default LandingHeader
