import Pusher from 'pusher-js'

const PUSHER_APP_ID = process.env.REACT_APP_PUSHER_APP_ID || '36512608'
const PUSHER_APP_KEY = process.env.REACT_APP_PUSHER_APP_KEY || 'ujt3gjrv9yko6ds'
const PUSHER_APP_SECRET = process.env.REACT_APP_PUSHER_APP_SECRET || 'hqfsvn7e3d5wuw1'
const PUSHER_PORT = process.env.REACT_APP_PUSHER_PORT || '6010'
const PUSHER_APP_CLUSTER = process.env.REACT_APP_PUSHER_APP_CLUSTER || 'eu'
const PUSHER_WS_HOST = process.env.REACT_APP_PUSHER_WS_HOST || 'staging-api.comment8.ai'
const PUSHER_WS_AUTH_ENDPOINT = `${process.env.REACT_APP_API_URL}/broadcasting/auth`
  || 'https://staging-api.comment8.heraldapp.pw/api/broadcasting/auth'

Pusher.logToConsole = true

export const pusher = new Pusher(PUSHER_APP_KEY, {
  wsHost: PUSHER_WS_HOST,
  wsPort: +PUSHER_PORT,
  wssPort: +PUSHER_PORT,
  cluster: PUSHER_APP_CLUSTER,
  forceTLS: false,
  enableStats:false,
  enabledTransports: ['ws','wss'],
  authEndpoint: PUSHER_WS_AUTH_ENDPOINT,
  auth: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  },
})
